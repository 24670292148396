import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  CalendarEvent,
  ConflictResponse,
  Conflicts,
  Member,
  Place,
  ZoozaContextTypes,
  CustomTileSettings,
} from "../types/types";
import "../styles/calendar.css";
import { DailyAttendance } from "../types/schedule-type";
import { CalendarEventItem } from "./event-item";
import { useNavigate } from "react-router-dom";
import { ZoozaApi } from "../services/Axios";
import { LoadingButton } from "./loading-button";
import { handleError } from "../services/common-functions";
import { ZoozaContext } from "../services/zooza.context";

export const DailyOverview = ({
  place,
  members,
  events,
  date,
  getTrainersPlaces,
  attendances,
  tileSettings,
}: {
  place: Place | undefined;
  members: Member[];
  events: CalendarEvent[] | undefined;
  handleDate: (num: number) => void;
  date: string;
  getTrainersPlaces: () => void;
  attendances: DailyAttendance[] | undefined;
  tileSettings: CustomTileSettings;
}) => {
  const [loading, setLoading] = useState<number | null>(null);
  const [conflicts, setConflicts] = useState<CalendarEvent[]>([]); // Map of conflicts by time

  const navigate = useNavigate();
  const times = Array.from(Array(24).keys()).map((hour) => {
    const time = moment().hour(hour).minute(0).format("HH:mm");
    return {
      id: hour,
      time: time,
    };
  });
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const filteredEvents = place
    ? events?.filter((event) => event.place_id === place.id)
    : events;
  const onClickCreateEvent = (e: any) => {
    const [time, member_id, place_id] = e.target.id.split("-");

    navigate(
      `/registrations/create/?time=${time}&member=${member_id}&place=${place_id}&date=${moment(
        date
      ).format("YYYY-MM-DD")}`
    );
  };
  // const checkConflict = (
  //   member: Member,
  //   time: string,
  //   date: string
  // ): boolean => {
  //   const hour = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm").hour();
  //   return conflicts[hour]?.has(member.id) || false;
  // };
  const onAvailabilityCheck = async () => {
    try {
      const today = moment(date).format("YYYY-MM-DD");
      const times = { start: `${today} 00:00:00`, end: `${today} 23:59:59` };

      const results = await Promise.all(
        [...placeMembers, ...roomsMembers].map(async (member) => {
          const { data } = await ZoozaApi.post<ConflictResponse[]>(
            `users/${member.id}/availability/check`,
            [times]
          );

          // Using flatMap to flatten the array of conflicts
          return data.flatMap((c) =>
            c.conflicts.map((con: Conflicts) => {
              if (moment(con.end).isAfter(moment(con.end).endOf("d"))) {
                con.end = moment(con.end).endOf("d").format("YYYY-MM-DD");
              }
              const diff = moment(con.end, "YYYY-MM-DD HH:mm:ss").diff(
                moment(con.start, "YYYY-MM-DD HH:mm:ss")
              );
              const totalMinutes = moment.duration(diff).asMinutes();
              return {
                conflict_title: con.conflict,
                conflict_type: con.type,
                course_color: "rosso_corsa",
                place_id: member?.limit?.place_id,
                room_id: member?.limit?.room_id,
                start: con.start,
                date_time: con.start,
                end: con.end,
                duration: totalMinutes, // numeric total minutes
                id: con.object_id,
                trainer_id: member.id,
              };
            })
          );
        })
      );

      console.log(results.flatMap((c) => c));
      setConflicts(results.flatMap((c) => c) as CalendarEvent[]);
    } catch (error) {
      console.error("Error checking availability: ", error);
    }
  };
  useEffect(() => {
    setConflicts([]);
    if (place) {
      onAvailabilityCheck();
    }
  }, [place]);
  useEffect(() => {
    onAvailabilityCheck();
  }, [date, members]);

  //  const fetchConflicts = async () => {
  //     try {
  //       const today = moment(date).format("YYYY-MM-DD");
  //       const timeRange = {
  //         start: ${today} 00:00:00,
  //         end: ${today} 23:59:59,
  //       };

  //       const results = await Promise.all(
  //         members.map(async (member) => {
  //           const { data } = await ZoozaApi.post(
  //             users/${member.id}/availability/check,
  //             [timeRange]
  //           );
  //           return data[0]?.conflicts.map((conflict: any) => ({
  //             id: conflict-${member.id}-${conflict.start},
  //             trainer_id: member.id,
  //             date_time: conflict.start,
  //             duration: moment(conflict.end).diff(
  //               moment(conflict.start),
  //               "minutes"
  //             ),
  //             course_color: "red", // Conflict-specific styling
  //             course_name: "Lecturer is not available", // Label for conflict
  //           }));
  //         })
  //       );

  //       setConflicts(results.flat());
  //     } catch (error) {
  //       console.error("Error fetching conflicts:", error);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchConflicts();
  //   }, [date, members]);

  //   const renderConflict = (
  //     memberId: number,
  //     start: moment.Moment,
  //     end: moment.Moment,
  //     reason: string
  //   ) => {
  //     const top = start.hour() * 60 + start.minute();
  //     const height = end.diff(start, "minutes");

  //     return (
  //       <div
  //         key={${memberId}-${start.format()}}
  //         className="conflict-item"
  //         style={{
  //           position: "absolute",
  //           top: ${top}px,
  //           height: ${height}px,
  //           backgroundColor: "rgba(255, 0, 0, 0.3)",
  //           zIndex: 1,
  //         }}
  //       >
  //         <span>{reason}</span>
  //       </div>
  //     );
  //   };

  const handleDeleteLimit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(Number((e.target as HTMLButtonElement).id));
    try {
      const { data } = await ZoozaApi.delete(
        `/trainer_places/${(e.target as HTMLButtonElement).id}`
      );

      if (data) {
        getTrainersPlaces();
      }
    } catch (err: any) {
      handleError(err.response.data, "alert");
      setLoading(null);
    }
  };
  const placeMembers = members?.filter(
    (member) =>
      member.limit?.place_id === place?.id &&
      (member.limit?.room_id === null ||
        member.limit?.room_id === undefined ||
        member.limit?.room_id === 0)
  );
  const roomsMembers = members?.filter(
    (member) =>
      member.limit?.place_id === place?.id &&
      member.limit?.room_id !== null &&
      member.limit?.room_id !== undefined &&
      member.limit?.room_id !== 0
  );
  return (
    <div className="calendar">
      <div className="calendar-header"></div>
      <div className="calendar-body">
        <ul>
          {" "}
          {conflicts?.map((conflict) => (
            <CalendarEventItem
              attendances={undefined}
              key={conflict?.id}
              event={conflict as CalendarEvent}
              place={place}
              members={members}
              type={"conflict"}
              tileSettings={tileSettings}
            />
          ))}
          {filteredEvents?.map((event) => (
            <CalendarEventItem
              attendances={attendances}
              key={event?.id}
              event={event}
              place={place}
              members={members}
              type={"event"}
              tileSettings={tileSettings}
            />
          ))}
        </ul>
        <div className="calendar-lectors">
          <ul>
            <div style={{ height: 50 }}></div>
            <li className="place-separator">{place?.name}</li>
            {placeMembers?.map((member) => (
              <li key={member.id} style={{ position: "relative" }}>
                <p>
                  {member.first_name} {member.last_name}
                </p>
                <LoadingButton
                  onClick={handleDeleteLimit}
                  class="delete-place-member"
                  id={String(member?.limit?.id)}
                  name="x"
                  loading={loading === member?.limit?.id}
                />
              </li>
            ))}
            {place?.rooms.map((room) => (
              <React.Fragment key={room.id}>
                <li className="place-separator">{room.name}</li>
                <ul>
                  {roomsMembers
                    ?.filter(
                      (member) =>
                        Number(member?.limit?.room_id) === Number(room.id)
                    )
                    .map((member) => (
                      <li key={member.id} style={{ position: "relative" }}>
                        <p>
                          {member.first_name} {member.last_name}
                        </p>
                        <LoadingButton
                          onClick={handleDeleteLimit}
                          class="delete-place-member"
                          id={String(member?.limit?.id)}
                          name="x"
                          loading={loading === member?.limit?.id}
                        />
                      </li>
                    ))}
                </ul>
              </React.Fragment>
            ))}
          </ul>
        </div>
        {times?.map((time) => (
          <div className="time-slot" key={time.time}>
            <div className="time-cell">{time.time}</div>
            {place ? (
              <li className="place-separator"></li>
            ) : (
              <li className="place-separator"></li>
            )}
            {placeMembers?.map((member) => {
              return (
                <li
                  onClick={onClickCreateEvent}
                  id={`${time.time}-${member.id}-${place?.pid}`}
                  key={member.id}
                ></li>
              );
            })}
            {place?.rooms.map((room) => (
              <React.Fragment key={room.id}>
                <li className="place-separator"></li>
                <ul>
                  {roomsMembers
                    ?.filter(
                      (member) =>
                        Number(member?.limit?.room_id) === Number(room.id)
                    )
                    ?.map((member) => {
                      return (
                        <li
                          onClick={onClickCreateEvent}
                          id={`${time.time}-${member.id}-${room.pid}`}
                          key={member.id}
                        ></li>
                      );
                    })}
                </ul>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
