import { Link } from "react-router-dom";
import { DailyAttendance } from "../types/schedule-type";
import moment from "moment";
import {
  Member,
  Place,
  CalendarEvent,
  CustomTileSettings,
} from "../types/types";
import React, { useEffect, useState } from "react";
import { msg } from "../services/common-functions";
import { t } from "i18next";

type Props = {
  event: CalendarEvent;
  attendances: DailyAttendance[] | undefined;
  place: Place | undefined;
  members: Member[];
  type: "event" | "conflict";
  tileSettings: CustomTileSettings;
};

export const CalendarEventItem = ({
  event,
  attendances,
  place,
  members,
  type,
  tileSettings,
}: Props) => {
  const [paymentStatus, setPaymentStatus] = useState("unpaid");
  const [attendance, setAttendace] = useState<DailyAttendance>();

  const isRoom = Boolean(event.room_id);
  const memberPosition =
    members?.findIndex((member) => member.id === event.trainer_id) + 1;
  const placeMembers = members?.filter(
    (member) =>
      member.limit?.place_id === place?.id && member.limit?.room_id === 0
  );
  const eventTimeHours = Number(moment(event.date_time).format("HH")) + 1;
  const eventTimeMinutes = Number(moment(event.date_time).format("mm")) + 1;
  const isMobile = window.innerWidth < 600;
  const cellheiht = isMobile ? 70 : 100;
  const cellwidth = 150;
  const separatorHeight = isMobile ? 70 : 100;
  const topOffset = 50;

  const movePositionTop = memberPosition * cellheiht + topOffset;
  const movePositionLeftHours = eventTimeHours * +cellwidth;
  const movePositionLeftMinutes = eventTimeMinutes * 2.5;
  const width = event.duration * 2.5;

  const roomMembers = members
    ?.filter((member) => member.limit?.room_id === event.room_id)
    ?.sort((a, b) => Number(a.limit?.room_id) - Number(b.limit?.room_id));

  const previosRooms = place?.rooms?.filter((room) => room.id < event.room_id);
  const previosRoomsMembers = members?.filter(
    (member) =>
      member.limit?.room_id &&
      member.limit?.room_id < event.room_id &&
      member.limit?.place_id === place?.id
  );

  const memberRoomPostion =
    roomMembers?.findIndex(
      (member) =>
        member.id === event.trainer_id &&
        event.room_id === member.limit?.room_id
    ) + 1;
  const movePositionTopRoom =
    topOffset +
    (Number(previosRooms?.length) +
      2 +
      Number(previosRoomsMembers?.length) +
      1 +
      Number(placeMembers?.length) +
      memberRoomPostion -
      1) *
      cellheiht +
    (Number(previosRooms?.length) - 1 * separatorHeight);

  const start = moment(event.date_time);
  const end = start.clone().add(event.duration, "minutes");
  const [hover, setHover] = React.useState(false);
  useEffect(() => {
    const attendance = attendances?.filter(
      (item) => item.event_id === event.id
    );
    const paid = attendance?.find(
      (attendance) => attendance?.payment_status === "paid"
    );
    const unpaid = attendance?.find(
      (attendance) => attendance?.payment_status === "unpaid"
    );
    const partially_paid = attendance?.find(
      (attendance) => attendance?.payment_status === "partially_paid"
    );

    if (unpaid) {
      setPaymentStatus("unpaid");
    } else if (partially_paid) {
      setPaymentStatus("partially_paid");
    } else if (paid) {
      setPaymentStatus("paid");
    }
    setAttendace(attendance?.[0]);
  }, [attendances, event, members, place]);

  return (
    <>
      {type === "conflict" && event.conflict_type === "unavailable"}
      <Link
        onClick={(e) => {
          if (type === "conflict" && event.conflict_type === "unavailable") {
            e.preventDefault(); // Prevent navigation if condition is true
          }
        }}
        style={{
          zIndex: hover ? 9999 : "",
        }}
        to={
          type === "conflict" && event.conflict_type === "unavailable"
            ? ""
            : `${event.id}`
        }
      >
        <li
          key={event.date_time}
          className={"z2 event" + " " + event.course_color}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          style={{
            top: !isRoom ? movePositionTop : movePositionTopRoom,
            left: movePositionLeftHours + movePositionLeftMinutes - 3,
            width: hover ? (width >= cellwidth ? width : cellwidth) : width,
            transition: "ease 0.3s",
          }}
        >
          {type === "event" && (
            <div className="event__title" key={attendance?.full_name}>
              <div
                className="status-circle"
                style={{
                  background:
                    paymentStatus === "paid"
                      ? "#0cc429"
                      : paymentStatus === "partially_paid"
                      ? "#ffbf00"
                      : "#ff3000",
                }}
              ></div>
              <div>
                {tileSettings.lecturer
                  ? `${t("calendar.trainer")} ${event?.trainer}`
                  : ""}
              </div>
              <div className="event-no"></div>
              {tileSettings.attendance
                ? attendance?.ef_full_name
                  ? attendance?.ef_full_name
                  : attendance?.full_name
                : ""}{" "}
              {tileSettings.icons && (
                <div>
                  <span className="icon-layer"></span> {event?.registrations} |{" "}
                  <span className="icon-paste"></span> {event?.attended} |{" "}
                  {event.event_number && event.total_events && (
                    <span className="icon-calendar"></span>
                  )}{" "}
                  {event?.event_number ? event.event_number : 0} /{" "}
                  {event?.total_events ? event.event_number : 0}{" "}
                </div>
              )}
              {tileSettings.extraFields && (
                <>
                  {tileSettings.ef_business_address &&
                    attendance?.ef_business_address && (
                      <div>{attendance?.ef_business_address}</div>
                    )}
                  {tileSettings.ef_business_id &&
                    attendance?.ef_business_id && (
                      <div>{attendance?.ef_business_id}</div>
                    )}
                  {tileSettings.ef_business_name &&
                    attendance?.ef_business_name && (
                      <div>{attendance?.ef_business_name}</div>
                    )}
                  {tileSettings.ef_extra_field_1 &&
                    attendance?.ef_extra_field_1 && (
                      <div>{attendance?.ef_extra_field_1}</div>
                    )}
                  {tileSettings.ef_extra_field_2 &&
                    attendance?.ef_extra_field_2 && (
                      <div>{attendance?.ef_extra_field_2}</div>
                    )}
                  {tileSettings.ef_extra_field_3 &&
                    attendance?.ef_extra_field_3 && (
                      <div>{attendance?.ef_extra_field_3}</div>
                    )}
                  {tileSettings.ef_extra_field_4 &&
                    attendance?.ef_extra_field_4 && (
                      <div>{attendance?.ef_extra_field_4}</div>
                    )}
                  {tileSettings.ef_extra_field_5 &&
                    attendance?.ef_extra_field_5 && (
                      <div>{attendance?.ef_extra_field_5}</div>
                    )}
                  {tileSettings.ef_tax_id && attendance?.ef_tax_id && (
                    <div>{attendance?.ef_tax_id}</div>
                  )}
                  {tileSettings.ef_vat && attendance?.ef_vat && (
                    <div>{attendance?.ef_vat}</div>
                  )}
                </>
              )}
              {tileSettings.courseName ? event?.course_name : ""}
              {event?.course_name ? <br /> : ""}
              {tileSettings.groupName ? event?.schedule_name : ""}{" "}
              {event?.schedule_name ? <br /> : ""}
              {tileSettings.duration ? event?.time : ""} -{" "}
              {tileSettings.duration ? moment(end).format("HH:mm") : ""}{" "}
              {tileSettings.duration ? `${event?.duration} min` : ""}
            </div>
          )}
          {type === "conflict" && (
            <div className="event__title">
              <div className="event-no"></div>
              {event?.conflict_title} <br />
              {msg("conflict_" + event?.conflict_type)}
            </div>
          )}
        </li>
      </Link>
    </>
  );
};
